<template>
  <div class="login full-layout">
    <div class="login__container">
      <div class="login__image-block">
        <img alt="" class="login__image" src="../../assets/logo.png" />
        Восстановление пароля
      </div>
      <template v-if="$route.query.token">
        <div class="login__inputs-block">
          <zem-input v-model="user.password" :max-length="255" placeholder="Введите новый пароль" type="password" />
          <div class="login__inputs-block__buttons">
            <zem-button @click="handleSendPassword">Сохранить</zem-button>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="login__inputs-block">
          <zem-input v-model="user.email" :max-length="255" placeholder="Введите email" type="email" />
          <div class="login__inputs-block__buttons">
            <zem-button @click="handleSendEmail">Отправить</zem-button>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import ZemInput from '../../components/ui/ZemInput.vue'
import ZemButton from '../../components/ui/ZemButton.vue'
import ZemLink from '../../components/ui/ZemLink.vue'
import AuthService from '@/services/auth.service'
import toastMessage from '@/helpers/messages'
import {errorHandler} from '@/assets/scripts/scripts'

export default {
  name: 'Login',
  components: {
    ZemInput,
    ZemButton,
    ZemLink,
  },
  data() {
    return {
      user: {
        email: '',
        password: '',
      },
      loading: false,
      message: '',
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.$router.replace('/profile')
    }
  },

  methods: {
    async handleSendEmail() {
      this.loading = true
      let isValid = true
      if (!isValid) {
        this.loading = false
        return
      }
      if (this.user.email.length > 0) {
        await AuthService.sentEmail(this.user)
          .then(() => {
            this.$router.push({name: 'password-reset-sent'})
          })
          .catch(e => {
            errorHandler(e)
          })
      }
    },
    async handleSendPassword() {
      this.loading = true
      let isValid = true
      if (!isValid) {
        this.loading = false
        return
      }
      if (this.user.password.length > 0) {
        await AuthService.resetPassword({
          email: this.$route.query.email,
          token: this.$route.query.token,
          password: this.user.password,
        }).then(() => {
          this.$toast.success(toastMessage.resetPasswordSuccess)
          this.$router.push({name: 'login'})
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  align-content: center;
  height: 70%;

  &__container {
    width: 240px;
    padding: 25px;
    background: #ffffff;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08),
      0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
  }

  &__description-block {
    color: #9ba6b2;
    margin-top: 12px;
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    text-align: center;
  }

  &__image {
    margin-bottom: 26px;
    height: 30px;
  }

  &__image-block {
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    color: $color-mine-shaft;
    justify-content: center;
    align-items: center;
    margin-bottom: 22px;
  }

  &__inputs-block {
    width: 100%;

    &__buttons {
      margin-top: 12px;
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    .zem-input {
      margin-bottom: 16px;
    }
  }
}
</style>
